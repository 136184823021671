import React from "react"

import ReactGA from "react-ga"

import btnVectorImage from "../../images/solo/btn-vector.svg"
import btnVectorArrowImage from "../../images/luca-pay/btn-vector-arrow.svg"

export default function Start({pricingPage}) {

  const getStartedNowClick = (navItem) => {
    ReactGA.event({
      category: `SS LP_Bottom Banner_Get Started Now`,
      action: `SS LP_Bottom Banner_Get Started Now`,
    })
  }

  return (
    <section className="start">
      <div className="container  flex  items-center h-full">
        <div className="flex flex-col lg:flex-row justify-between items-center mx-auto w-full sm:pb-0 pt-4">
          <div className="left text-white text-center lg:text-left">
            <h2 className=" d-title mt-0 pt-0">
              Frankly, you are losing sleep over extra paperwork
              and chasing payments, let us help.
            </h2>
          </div>
          <div className='sm:pb-0 pb-4 sm:pt-0 pt-10'>
            <a
              onClick={getStartedNowClick}
              className="get-started-btn sm:ml-12 cursor-pointer focus:outline-none flex justify-center items-center relative"
              href={pricingPage}
            >
              Get Started Now
              <img src={btnVectorImage} className="arrow" alt="right arrow"/>
              <img src={btnVectorArrowImage} className="absolute vector-arrow" alt="right arrow"/>
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}
