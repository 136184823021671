import React, { useEffect, useState } from "react"

import rightVectorImg from "../../images/solo/right-vector.svg"
import leftVectorImg from "../../images/solo/left-vector.svg"
import coursolImg1 from "../../images/standard/courier.png"
import coursolImg2 from "../../images/standard/construction.svg"
import coursolImg3 from "../../images/standard/IT-service.svg"
import coursolImg4 from "../../images/standard/facilities.png"

import ReactGA from "react-ga"


export default function Persona() {

  const [activePersona, setActivePersona] = useState(0)

  useEffect(() => {
    const debounce = setTimeout(() => nextActivePersona(), 3000)
    return () => clearTimeout(debounce)
  })

  const navItemClick = (navItem) => {
    ReactGA.event({
      category: `Solo Plan Pricing Page_Nav Bar_${navItem}`,
      action: `Solo Plan Pricing Page_Nav Bar_${navItem}`,
    })
  }

  const nextActivePersona = () => {
    setActivePersona(activePersona === personaData.length - 1 ? 0 : activePersona + 1)
  }

  const prevActivePersona = () => {
    setActivePersona(activePersona === 0 ? personaData.length - 1 : activePersona - 1)
  }

  return (
    <div className="tools">
      <div className="container flex justify-center">
        <div className="testimonial-section w-full">
          <div className="header">
            Is this dreadful invoicing cycle too close to home for you?
          </div>
          <div className="text-neutral-3 text-16 sm:mt-5 mt-2 mb-2 subtext flex">
            <div className="sm:block hidden">
              You’re in the right place! Manage your cash flow and improve your back-office efficiency
            </div>
            <div className="block sm:hidden">
              You’re in the right place!
            </div>
          </div>

          <div className="flex items-center img-wrap">
            <div onClick={prevActivePersona} className="cursor-pointer left-arrow">
              <img src={leftVectorImg} />
            </div>
            <div className="slider-text-wrap">
              <div className='sm:block flex justify-between'>
                <img src={personaData[activePersona].img} className='character'/>
              </div>
              <div className="sm:text-left text-center sm:justify-start justify-center flex text-neutral-3 content">
                <span className="sm:block hidden">{personaData[activePersona].text}</span>
                <span className="block sm:hidden">{personaData[activePersona].mobileText}</span>
              </div>
            </div>
            <div onClick={nextActivePersona} className="cursor-pointer right-arrow">
              <img src={rightVectorImg} />
            </div>
          </div>
          <div className="flex sm:mt-0 mt-5">
            {personaData.map((data, i) => <div className={`${i === activePersona && "active"} coursol mx-1 cursor-pointer`} onClick={() => setActivePersona(i)} />)}
          </div>
        </div>
      </div>
    </div>
  )
}

const personaData = [
  {
    img: coursolImg1,
    text: "David is considering putting a number of his delivery trucks out from commission. He is struggling with cash flow from outstanding invoices from consignees saying some invoices are missing, have been misplaced or are incorrect.",
    mobileText: "David is struggling with cash flow because of outstanding invoices from consignees saying some invoices are missing, have been misplaced or are incorrect.",
  },
  {
    img: coursolImg2,
    text: "Paul has sent out progress billing for a house project and a couple more invoices hoping to have the funds used for his business, especially on supplies.",
    mobileText: "Cindy has sent a number of invoices for her cleaning company. This took her an extra 2 hours for a day’s tasks.",
  },
  {
    img: coursolImg3,
    text: "Tom spends his evenings invoicing and chasing suppliers for his construction projects. It can take 3-5 weeks to receive payment, even for the smallest jobs.",
    mobileText: "Tim owns a small IT business where most of his clients are medium to larger businesses. Payment times take longer than 90 days.",
  },
  {
    img: coursolImg4,
    text: "Lauren has to deal with the setbacks from last year prompting her to take a second mortgage just to keep payroll and business afloat",
    mobileText: "Lauren has to deal with the setbacks from last year prompting her to take a second mortgage just to keep payroll and business afloat.",
  },
]
