import React, { useEffect, useRef, useState } from "react"

import faqBorderImg from "../../images/new-pricing/faq-bh-border.svg"
import minusImg from "../../images/new-pricing/minus.svg"
import plusImg from "../../images/new-pricing/plus.svg"
import ReactGA from "react-ga"

export default function Demo() {
  const [activeCollapse, setActiveCollapse] = useState(0)

  const toggleCollapse = (collapse) => {
    if (collapse === activeCollapse) {
      setActiveCollapse(0)
    } else {
      setActiveCollapse(collapse)
    }
    ReactGA.event({
      category: `Solo Plan Pricing Page_Frequently Asked Questions`,
      action: `Solo Plan Pricing Page_Frequently Asked Questions`,
    })
  }

  return (
    <div className="faq mx-auto">
      <div className="header relative">
        <div className="flex flex-col">
          Frequently Asked Questions
          <img src={faqBorderImg} className="mx-auto sm:w-auto w-5/6" />
        </div>
      </div>
      <div className='faq-header-wrap'>
      {
        standardFaq.map((data, index) => <div>
          <div className="faq-header flex items-center justify-between cursor-pointer"
               onClick={() => toggleCollapse(index + 1)}>
            {data.title}
            <div>
              {activeCollapse === index + 1 ? <img src={minusImg} />
                : <img src={plusImg} />}
            </div>
          </div>
          {activeCollapse === index + 1 &&
          <div className="content">
            {data.content}
          </div>}
        </div>)
      }
      </div>
    </div>
  )
}

export const standardFaq = [{
  title: "What is LUCA Plus?",
  content: <span>LUCA Plus is the world’s first end to end business transaction network, allowing businesses to instantly, and completely, process invoices, whether sending or receiving them. Using your current accounting solution, LUCA Plus synchronises your invoices and bills with your customers or suppliers, cutting out repetitive tasks and letting you get back to business. In doing so, LUCA Plus is estimated to save businesses up to  70% of the costs associated with account maintenance and administration.</span>,
},
  {
    title: "How does invoicing work?",
    content: <span>Invoicing with LUCA Plus is simple as 1-2-3.<br />
      1 - Signup to complete your business information <br />
      2 - Sync LUCA PLus with your existing accounting software. <br />
      3 - Send and receive invoices from your existing accounting software, Luca PLUS takes care of the rest in the background.
    </span>,
  },
  {
    title: "Do both parties need to be LUCA Plus subscribers for e-invoicing to work?",
    content: <span>Both parties need to be in the LUCA Plus network or PEOPPOL network to deliver e-invoices.<br /><br />
      By doing so, we can ensure the other party is legitimate. You are also being protected from potential fraudulent invoices and bills.
      <br /><br />
      It also allows simple and easy automation as both of you are part of the LUCA Plus network.
    </span>,
  },
]
