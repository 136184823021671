import React from "react"

import RightVectorImage from "../../images/new-landing/right-vector.svg"
import heroRightImg from "../../images/solo/hero-right.png"
import playIcon from "../../images/solo/play_icon.svg"
import heroRightVectorImg from "../../images/solo/hero-right-curve.svg"
import heroLeftVectorImg from "../../images/solo/hero-left-curve.svg"
import ReactGA from "react-ga"

export default function Hero({ toggleVideoModal, pricingPage }) {

  const signupClick = (navItem) => {
    ReactGA.event({
      category: `SS LP_Banner_Sign Up Now`,
      action: `SS LP_Banner_Sign Up Now`,
    })
  }

  const navItemClick = (navItem) => {
    ReactGA.event({
      category: `SS LP_Nav Bar_ Contact Us`,
      action: `SS LP_Nav Bar_ Contact Us`,
    })
  }

  return (
    <div className="hero">
      <div className="container custom-container flex justify-between">
        <div className="left">
          <div className="header sm:pr-12">
            <span className='sm:block hidden'>
              Automate & simplify accounting in a fast and secure way
            </span>
            <span className='sm:hidden block'>
              Less back-office stress. More business growth
            </span>
          </div>
          <div className="text-neutral-3 text-16 my-5 sm:w-3/4 sm:pr-4">
            <span className='sm:block hidden'>
              So much to do with overseeing daily operations, suppliers, and accounting tasks, in so little time? <br /><br />
              LUCA Plus is an all-in-one accounting tool that helps improve your cash flow and back-office inefficiencies.
            </span>
            <span className='block sm:hidden'>
              LUCA Plus is an all-in-one accounting tool that helps improve your cash flow and back-office inefficiencies.
            </span>
          </div>
          <a
            onClick={signupClick}
            className="signup-btn focus:outline-none uppercase justify-center items-center cursor-pointer hidden sm:flex"
            href={pricingPage}
          >
            Sign Up Now
            <img src={RightVectorImage} className="ml-2 sm:ml-3 arrow" alt="right arrow" />
          </a>
          <div className="flex sm:hidden items-center justify-content-center">
            <a
              onClick={navItemClick}
              className="book-demo-btn focus:outline-none flex justify-center items-center mr-3"
              href="/contact"
            >
              Contact us
            </a>
            <a
              onClick={signupClick}
              className="signup-btn focus:outline-none uppercase flex justify-center items-center cursor-pointer"
              href={pricingPage}
            >
              Sign Up Now
              <img src={RightVectorImage} className="ml-2 sm:ml-3 arrow" alt="right arrow" />
            </a>
          </div>
        </div>
        <div className="right sm:mt-0 mt-8">
          <img className="hero-left-img" src={heroLeftVectorImg} />
          <div className="hero-right-main-img -mb-10"><img src={heroRightImg} /></div>
          <div className="play-icon cursor-pointer" onClick={toggleVideoModal}><img src={playIcon} /></div>
          <img src={heroRightVectorImg} className="hero-right-img" />
        </div>
      </div>
    </div>
  )
}
