import React  from "react"
import ReactGA from "react-ga";

import SEO from "../components/seo"

import Modali, { useModali } from "../components/modal"
import Top from "../components/standard/top"
import Hero from "../components/standard/hero"
import Persona from "../components/standard/persona"
import Invoice from "../components/standard/invoice"
import Activity from "../components/standard/activity"
import Faq from "../components/standard/faq"
import Start from "../components/standard/start"
import Footer from "../components/pricing/footer"

import "../scss/style.scss"
import { navigate } from "gatsby"

export default function StarterStandardLp() {
  const [videoModal, toggleVideoModal] = useModali({
    onShow: () => openVideoModal(),
    onHide: () => closeVideoModal(),
  })

  const openVideoModal = () => {
    ReactGA.event({
      category: 'SP_LP_video_open_watch',
      action: `SP_LP_video_open_watch`
    });
  }

  const closeVideoModal = () => {
    ReactGA.event({
      category: 'SP_LP_video_open_close',
      action: `SP_LP_video_open_close`
    });
  }

  const pricingPage = '/starter-standard-pricing-page';

  return (
    <div className="h-full solo_landing standard">
      <SEO title="LUCA Plus is an all-in-one accounting tool that helps improve your cash flow and back-office inefficiencies." einvoice={true}
           description="Automate your invoicing process in less than 30 seconds. Enjoy our bonus income projection feature to prepare you for up and coming cash flow challenges."
      keywords="Starter standard lp" />
      <Top />
      <Hero toggleVideoModal={toggleVideoModal} pricingPage={pricingPage}/>
      <Persona />
      <Activity pricingPage={pricingPage} />
      <Invoice pricingPage={pricingPage} />
      <Faq />
      <Start pricingPage={pricingPage} />
      <Footer solo={true} page='SS LP' />
      <Modali.Modal {...videoModal}>
        <div className="hero-video">
          <iframe
            src="https://www.youtube.com/embed/L8Q7ScsjLtQ?autoplay=1&enable_js=1"
            title="Introduction to LUCA+"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            allow="autoplay"
          />
        </div>
      </Modali.Modal>
    </div>
  )
}
