import React from "react"

import RightVectorImage from "../../images/new-landing/right-vector.svg"
import triangleImg from "../../images/solo/triangle.svg"
import painterImg from "../../images/solo/painter.svg"
import ReactGA from "react-ga"

export default function Activity({pricingPage}) {

  const getStartedClick = () => {
    ReactGA.event({
      category: `SS LP_Get Started Now`,
      action: `SS LP_Get Started Now`,
    })
  }

  return (
    <div className="activity">
      <div className="container flex sm:flex-row flex-col justify-content-center">
        <div className='left'>
          <div className="header">
              ABS estimates Cash flow is the leading contributor to stress for 70% of small businesses
          </div>
          <div className="text-neutral-3 text-16 sm:text-left text-center my-6">
            <span className='sm:block hidden'>
             Nearly all SMEs (92%) believe they would have generated more revenue over the past 12 months if their cash flow situation
              was improved.<br /><br />
              Late payment accounted for <span className='italic font-semibold'>43% of the cash flow downturn </span>. Construction, facilities and road freight industries are 5.2-11% less
              likely to get paid on time.<br /><br />
              The long painful hours of matching invoices from suppliers add up to 10 hours a week of manual data entry for the business owner.
              Awkward phone calls following up on invoices can take up to an additional 10 hours of administrative effort. <br /><br />
              There has to be a better way to take care of all these business accounting tasks! LUCA Plus automates the invoicing process literally
              allowing you to process invoices in less than 30 seconds. The bonus income projection feature prepares you for up and coming cash flow challenges over the next month. But we’re to help when you get stuck,  LUCA Pay Buy-Now-Pay-Later can help you get paid on time.
            </span>
            <span className='block sm:hidden'>
              Late payment accounted for 43% of the cash flow downturn. Construction, facilities and road freight industries are 5.2-11% less likely to get paid on time.
            </span>
          </div>
          <a
            onClick={getStartedClick}
            className="start-invoicing-btn bg-mind text-white px-3 py-2 focus:outline-none uppercase flex justify-center items-center cursor-pointer sm:mx-0 mx-auto"
            href={pricingPage}
          >
            Get started Now
            <img src={RightVectorImage} className="ml-2 sm:ml-3 arrow" alt="right arrow" />
          </a>
        </div>
        <div className='sm:ml-5 right-section'>
          <div className='flex justify-content-center items-center top'>
            <div className='flex relative w-full'>
              <img className='tri-img' src={triangleImg} />
              <div className='label'>
                Amy’s First Few Months of Business Activity
              </div>
            </div>
            <img src={painterImg} className='sm:block hidden' />
          </div>
          <div className='business-activity-wrapper text-14'>
            <div className='business-list'>
              <span className='number'> 01 </span>
              <span className='text-neutral-3 text'>You bill no less than 5 jobs a day</span>
            </div>
            <div className='business-list flex justify-end w-full '>
              <span className='text-neutral-3 mr-3 text'>You spend 2 extra hours daily to match invoices and reissue lost or incorrect invoices</span>
              <span className='number custom-margin'>02</span>

            </div>
            <div className='business-list'>
              <span className='number'> 03 </span>
              <span className='text-neutral-3 text'>
                  Your subcontractors have either lost their paper invoice or can’t find the emailed invoice
              </span>
            </div>
            <div className='business-list flex justify-end w-full '>
              <span className="text-neutral-3 text mr-3">
                 <span className="sm:block hidden">
                   On top of that, you now start losing track of what invoices have been paid or haven’t. Now you feel terribly
                   frustrated and start questioning your decision to build this small-medium business
                </span>
                <span className="sm:hidden block">
                  You start losing track of what invoices have been paid or haven’t. Now you feel terribly frustrated.
                </span>
                </span>
              <span className="number custom-margin">04</span>
            </div>
            <div className='business-list'>
              <span className='number'> 05 </span>

              <span className='text-neutral-3 text'>
                  <span className="sm:block hidden">
                    What’s worse, because of the many outstanding invoices, your bank account goes into overdraft,
                    forcing you to borrow even more money from loans to keep your business running
                  </span>
                  <span className="sm:hidden block">
                    The many outstanding invoices cause you to borrow even more money from loans to keep your business running.
                  </span>
                </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
