import React from "react"

import invoiceImg from "../../images/solo/invoice.svg"
import invoiceMobileImg from "../../images/solo/invoice-mobile.png"

import ReactGA from "react-ga"
import RightVectorImage from "../../images/new-landing/right-vector.svg"

export default function Invoice({pricingPage}) {

  const startInvoiceClick = (navItem) => {
    ReactGA.event({
      category: `SS LP_Start Invoicing Now`,
      action: `SS LP_Start Invoicing Now`,
    })
  }

  return (
    <div className="invoice container flex justify-start items-center sm:flex-row flex-col-reverse px-5 sm:px-10 sm:mt-24 mt-12">
      <div className="invoice-img">
        <img src={invoiceImg} className="w-full sm:block hidden" />
        <img src={invoiceMobileImg} className="w-full block sm:hidden" />
      </div>
      <div className="content ">
        <div className="header">
          Build stronger business relationships
        </div>
        <div className="text-neutral-3 text-16 sm:text-left text-center my-6 sm:px-0 px-5">
           Efficiency is a top priority for every SME. May it be getting invoices paid on time or projecting income to make smarter decisions.
           LUCA Plus can be integrated with your existing accounting software for a faster and secure payment process.
        </div>
        <a
          onClick={startInvoiceClick}
          className="get-started-btn cursor-pointer focus:outline-none justify-center items-center relative sm:flex hidden uppercase"
          href={pricingPage}
        >
          Start INVOICING Now
          <img src={RightVectorImage} className="ml-2 sm:ml-3 arrow" alt="right arrow" />
        </a>
      </div>
    </div>
  )
}
